.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container .ngx-toastr {
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 0.75rem 1.25rem 0.75rem 50px;
  width: 300px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
  pointer-events: auto;
}
.toast-container .ngx-toastr .toast-title {
  font-weight: bold;
}
.toast-container .ngx-toastr .toast-message {
  word-wrap: break-word;
}
.toast-container .ngx-toastr .toast-message a:hover {
  text-decoration: underline;
}
.toast-container .ngx-toastr .toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  background: transparent;
  border: 0;
  padding: 0;
}
.toast-container .ngx-toastr .toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.3;
}
.toast-container .ngx-toastr .toast-close-button:hover,
.toast-container .ngx-toastr .toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.75;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  opacity: 1;
  cursor: pointer;
}

.toast-success {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/check.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(21, 87, 36, 0.999999)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.toast-error {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/times-circle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(114, 28, 36, 0.999999)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

.toast-info {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/info-circle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(12, 84, 96, 0.999999)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
  color: #0c5460;
  background-color: #d1ecf1;
  border: 1px solid #bee5eb;
}

.toast-warning {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/exclamation-triangle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgba(133, 100, 4, 0.999999)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
}

@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #2d3131;
}

.videoe-m {
  vertical-align: middle;
  object-fit: cover;
  background: black;
  position: absolute;
  height: 100%;
  width: 100%;
}

.videoel {
  vertical-align: middle;
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.mainContainer {
  height: 100%;
  padding: 10px;
  width: 100%;
  background-color: #1C1F2E;
}

.sideContainer {
  width: 350px;
  background-color: gray;
}

.containerHeight {
  height: calc(100% - 56px);
}

.btn-bd-primary {
  font-weight: 600;
  color: #fff;
  background-color: #ffa511;
  border-color: #cd830a;
}

.btn-bd-primary:hover,
.btn-bd-primary:active {
  color: #fff;
  background-color: #ffa511;
  border-color: #cd830a;
}

.modal-content {
  background-color: white !important;
  color: #6c6f72 !important;
  border-radius: 5px;
  -webkit-box-shadow: 4px 3px 19px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 3px 19px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 3px 19px 0px rgba(0, 0, 0, 0.75);
}

.displayNone {
  display: none;
}

.displayShow {
  display: block;
}

.form-control {
  background-color: #fff;
  border: 1px solid #9fa3a7;
  color: #5c5e60;
}

.controlContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #fff;
  width: 200px;
  margin: 5px;
  height: 50px;
  padding: 5px;
}

.boderColor {
  padding: 5px;
  border-color: #444 !important;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 10px;
  right: 40px;
  background-color: #242736;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.my-float {
  color: white;
  margin-top: 22px;
}

app-overlap {
  height: 100%;
  width: 100%;
}

app-sidelayout {
  height: 100%;
  width: 100%;
}

.controlIconON {
  font-size: 24px;
  color: #F8F8F9;
  background: #242736;
  border-radius: 50px;
  padding: 12px;
  transition: 0.3s;
  font-size: 18px;
  border-radius: 5px;
  padding: 5px;
}

.nameControlBar {
  font-size: 32px;
  height: 15%;
  width: 100%;
  margin-bottom: 1%;
  position: absolute;
}

.nameControlDiv {
  display: flex;
  height: 100%;
  padding: 3%;
  justify-content: left;
  align-items: center;
  transition: 0.3s;
}

.controlDiv {
  display: flex;
  height: 100%;
  padding: 3%;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.containerRelative:hover > .player-buttons .controlDiv {
  opacity: 1;
}

.containerRelative:hover > .nameControlBar .nameControlDiv {
  opacity: 1;
}

.containerRelative:hover > .player-buttons .controlDiv .controlItemHover {
  font-size: 24px;
  color: #F8F8F9;
  background: #2ecc71;
  border-radius: 50px;
  padding: 12px;
}

.containerRelative:hover > .player-buttons .controlDiv .controlItemHoverOn {
  font-size: 24px;
  color: #F8F8F9;
  background: #2ecc71;
  border-radius: 50px;
  padding: 12px;
}

.containerRelative:hover > .player-buttons .controlDiv .controlItemHoverPowerOn {
  font-size: 24px;
  color: #F8F8F9;
  background: red;
  border-radius: 50px;
  padding: 12px;
}

.outer:hover > .player-buttons .controlDiv {
  opacity: 1;
}

.outer:hover > .player-buttons .controlDiv .controlItemHover {
  font-size: 24px;
  color: #F8F8F9;
  background: #e74c3c;
  border-radius: 100px;
  padding: 18px;
  width: 63px;
  height: 63px;
}

.outer:hover > .player-buttons .controlDiv .controlItemHoverOn {
  font-size: 24px;
  color: #F8F8F9;
  background: #2ecc71;
  border-radius: 100px;
  padding: 18px;
  width: 63px;
  height: 63px;
}

.outer:hover > .player-buttons .controlDiv .controlItemHoverPowerOn {
  font-size: 24px;
  color: #F8F8F9;
  background: red;
  border-radius: 100px;
  padding: 18px;
  width: 63px;
  height: 63px;
}

.waitingState {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logged-in {
  color: #2ecc71 !important;
}

.controlItemsVideoOverlap {
  width: 50px;
  text-align: center;
}

.nameHolder {
  font-size: 18px;
  display: flex;
  color: #F8F8F9;
  background: #161c22;
  padding: 10px;
  border-radius: 15px;
  opacity: 0.7;
}

.microphone {
  text-align: center;
  width: 51px;
}

.field {
  padding: 3px;
  border-radius: 4px;
  border-color: darkgrey;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 4px;
  /* Width of the scrollbar */
  background-color: #2d3131;
}

::-webkit-scrollbar-thumb {
  background-color: #5b6363;
  /* Scrollbar thumb color */
  border-radius: 5px;
  /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #7f8686;
  /* Darker on hover */
}

::-webkit-scrollbar-track {
  background-color: #2d3131;
}

/* Ensure the scrollbar is always visible */
html {
  overflow-y: scroll;
  /* Forces a scrollbar to always be present */
}